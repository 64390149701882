<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Set Password : {{ user.first_name }} {{ user.last_name }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>


        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">New Password</label>
              <b-input type="password" v-model="$v.user.new_password.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('new_password')" v-on:input="resetServerError('new_password')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.new_password.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.new_password.minLength">Password must be at least 6 characters long.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="server_errors.new_password">{{server_errors.new_password}}</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">New Password Confirm</label>
              <b-input type="password" v-model="$v.user.new_password_confirm.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('new_password_confirm')" v-on:input="resetServerError('new_password_confirm')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.new_password_confirm.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.new_password_confirm.minLength">Password must be at least 6 characters long.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.new_password_confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="server_errors.new_password_confirm">{{server_errors.new_password_confirm}}</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="setUserPassword" class="float-right">Set password</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

  export default {
    name: 'UserSetPassword',
    components: {Id},
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'User : Set Password'
      },
    },
    data: () => {
      return {
        user: {
          first_name: '',
          last_name: '',
          new_password: '',
          new_password_confirm: ''
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          user: null,
          new_password: null,
          new_password_confirm: null,
        },
        options: {
          groups: []
        }
      }
    },
    validations() {

      return {
        user: {
          new_password: {
            required,
            minLength: minLength(6),
            serverRequired () { return Boolean(!this.server_errors.new_password) }
          },
          new_password_confirm: {
            required,
            sameAsPassword: sameAs('new_password'),
            serverRequired () { return Boolean(!this.server_errors.new_password_confirm) }
          },
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.user[name]) {
          const {$dirty, $error} = this.$v.user[name];
          return $dirty ? !$error : null;
        }
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getUser() {
        axios.get('/users/' + this.$route.params.id + '/').then(
          response => {
            this.user.first_name = response.data.first_name;
            this.user.last_name = response.data.last_name;
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      setUserPassword() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = {
            user: this.$route.params.id,
            new_password: this.user.new_password,
            new_password_confirm: this.user.new_password_confirm,
          }

          axios.put('/users/set-password/', post_data).then(
            response => {
              //this.user = response.data
              let alert = {
                type: 'success',
                text: 'User : ' + this.user.first_name + ' ' + this.user.last_name + ' password was updated successfully!'
              };

              this.$store.commit(
                'setGlobalAlert',
                alert
              )
              this.$router.replace('/users')
            }
          ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){
                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      this.getUser()
      this.$v.touch()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
